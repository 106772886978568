/* a.active {
    color: red;
  } */


  /* .active-link a.active{
    color: #f9ba14;
  } */



  .menu li > *:not(ul):not(.menu-title):not(details):active, .menu li > *:not(ul):not(.menu-title):not(details).active, .menu li > details > summary:active {
    --tw-bg-opacity: 1;
    background-color: #fff;
    --tw-text-opacity: 1;
    color: #ecc94b

  }